import { openEmailForm, revealprojects, revealSection } from "./functions";

let hero: HTMLDivElement = document.getElementById("hero") as HTMLDivElement;
let heroContainer: HTMLParagraphElement = document.getElementById(
  "hero__inner-container"
) as HTMLParagraphElement;

let skills: HTMLDivElement = document.getElementById(
  "skills"
) as HTMLDivElement;
let skillsInnerContainer: HTMLDivElement = document.getElementById(
  "skills__symbols"
) as HTMLDivElement;

let projects: HTMLDivElement = document.getElementById(
  "projects"
) as HTMLDivElement;
let projectsInnerContainer: HTMLDivElement = document.getElementById(
  "projects__inner-container"
) as HTMLDivElement;

let emailButton: HTMLAnchorElement = document.getElementById(
  "email"
) as HTMLAnchorElement;

let footer: HTMLDivElement = document.getElementById(
  "footer"
) as HTMLDivElement;

//Hero section
hero.addEventListener("click", () => {
  if (skillsInnerContainer.className === "show") {
    skillsInnerContainer.className = "hidden";
  }
  if (projectsInnerContainer.className === "show") {
    projectsInnerContainer.className = "hidden";
  }
  revealSection(heroContainer);
});

//Skills section
skills.addEventListener("click", () => {
  if (heroContainer.className === "show") {
    heroContainer.className = "hidden";
  }
  if (projectsInnerContainer.className === "show") {
    projectsInnerContainer.className = "hidden";
  }
  revealSection(skillsInnerContainer);
});

//Project section
projects.addEventListener("click", () => {
  //opens or closes hero section
  if (heroContainer.className === "show") {
    heroContainer.className = "hidden";
  }
  //opens or closes skills section
  if (skillsInnerContainer.className === "show") {
    skillsInnerContainer.className = "hidden";
  }
  revealprojects(projectsInnerContainer);
});

//Email-form
emailButton.addEventListener("click", () => {
  openEmailForm();
});

/* if ((projectsInnerContainer.className = "show")) {
  emailButton.style.display = "none";
} */
