export function revealSection(container: HTMLElement) {
  if (container.className === "hidden") {
    container.className = "show";
  } else {
    container.className = "hidden";
  }
}

export function revealprojects(container: HTMLElement) {
  if (container.className === "hidden") {
    container.className = "show";
  } else {
    container.className = "hidden";
  }
}

export function openEmailForm() {
  let modal = document.createElement("div");
  modal.setAttribute("id", "email__container");

  let closeButton = document.createElement("button");
  closeButton.setAttribute("id", "email__close-btn");
  closeButton.innerText = "close";
  closeButton.addEventListener("click", () => {
    removeEmailForm(modal);
  });

  let emailForm = document.createElement("form");
  emailForm.setAttribute("id", "email__form");
  emailForm.setAttribute(
    "action",
    "https://formsubmit.co/marcusreineck@gmail.com"
  );
  emailForm.setAttribute("method", "POST");
  emailForm.innerHTML = `
  <label name:"name">Name:</label>
  <input type="text" name="name" placeholder="John doe" required>
  <label name:"email">Email:</label>
  <input type="email" name="email" placeholder="your@email.com" required>
  <textarea placeholder="Write your message here!" class="form-control" name="message" rows="10" required></textarea>
  <button type="submit" id="email__submit-btn">Send</button>
  `;

  document.body.appendChild(modal);
  modal.appendChild(closeButton);
  modal.appendChild(emailForm);
}

function removeEmailForm(modal: HTMLDivElement) {
  if (modal!) {
    modal.remove();
  }
}
